import { IAuthConfig, IConfig } from './contracts/config.contract';

export default class Constants {
  private static config: IConfig;

  public static init(config: IConfig): void {
    this.config = config;
  }

  public static get authConfig(): IAuthConfig {
    return this.config.authConfig;
  }

  public static get supportEmailAddress(): string {
    return this.config.supportEmailAddress;
  }

  public static get pvfcUrl(): string {
    return this.config.pvfcUrl;
  }

  public static get privacyStatementNl(): string {
    return this.config.privacyStatement.nl;
  }

  public static get privacyStatementFr(): string {
    return this.config.privacyStatement.fr;
  }

  public static get cookieStatementNl(): string {
    return this.config.cookieStatement.nl;
  }

  public static get cookieStatementFr(): string {
    return this.config.cookieStatement.fr;
  }
}

import axios from 'axios';
import Vue from 'vue';
import Constants from './constants';
import { IConfig } from './contracts/config.contract';

Vue.config.productionTip = false;

(async () => {
  const config = (await axios.get<IConfig>('config.json')).data;
  Constants.init(config);

  import('@/app');
})();
